<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <span class="avenir-logo">
        <b-img
          :src="require('@/assets/images/logo/logo.png')"
          alt="logo"
        />
      </span>
      <h2 class="avenir-text">
        {{ appName }}
      </h2>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getFullName() }}
            </p>
            <span class="user-status">{{ getRoles() }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="showChangePasswordModal"
        >
          <feather-icon
            size="16"
            icon="KeyIcon"
            class="mr-50"
          />
          <span>{{ $t('navigation.changePassword') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('navigation.logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal
      ref="password-expiration-modal"
      hide-footer
      :title="$t('login.changePassword.title')"
    >
      <br>
      <change-password />
      <br>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BImg,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import UserStoreModule from '@/views/user/storeModule'
import ChangePassword from '@/views/auth/password/ChangePassword.vue'
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BImg,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
    ChangePassword,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: {},
      avatar: '',
    }
  },
  created() {
    // Register module
    if (!store.hasModule('app-user')) store.registerModule('app-user', UserStoreModule)
    this.userData = JSON.parse(localStorage.getItem('userData'))

    if (this.userData && this.userData.avatarId) {
      this.fetchAvatar(this.userData.avatarId)
    }
  },
  methods: {
    getFullName() {
      if (this.userData) {
        return this.userData.fullName
      }
      return ''
    },
    getRoles() {
      if (this.userData) {
        const { roles } = this.userData
        if (roles && roles.length > 0) {
          let roles_str = roles[0].name
          for (let i = 1; i < roles.length; i++) {
            roles_str += ` / ${roles[i].name}`
          }

          if (roles_str.length > 70) {
            roles_str = `${roles_str.substring(0, 70)}...`
          }
          return roles_str
        }
      }
      return ''
    },
    showChangePasswordModal() {
      console.info('show modal')
      this.$refs['password-expiration-modal'].show()
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    fetchAvatar(avatarId) {
      store.dispatch('app-user/fetchImage', { id: avatarId })
        .then(response => {
          this.avatar = response.data.img
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.userData)
            this.userData = undefined
          }
        })
    },
  },
  setup() {
    return {
      appName: $themeConfig.app.appName,
    }
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/variables/_variables.scss';
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 15rem;
}
.avenir-logo{
  img {
    max-width: 36px;
  }
}
.avenir-text{
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 10px;
  color: $primary;
}
</style>
